import React, { useState, useEffect } from "react";
import Img from "gatsby-image";

import Container from "../../components/Container/container";
import CTA from "../../components/CTA/cta";
import navigateToCheckout from "../../utils/navigate-checkout";
import { ProductTypes, tearribleStyles } from "../../types";
import "./upsell-select.scss";
import { useInventory } from "../../hooks/useInventory";
import {
	CollarColor,
	CollarSize,
	HarnessSize,
	LeashColor,
	LeashSize,
	PoopBagHolderColor,
} from "../CollarOptions/collar-options";

const WeightUnits = {
	POUNDS: "POUNDS",
	OUNCES: "OUNCES",
};

const usePrimaryInventory = (handle, sku) => {
	sku = sku?.split(":")?.[0];

	const { data: inventoryData, isLoading } = useInventory(
		handle.replace("-special-offer", "").replace("-2", ""),
	);

	if (handle.includes("combo") || handle.includes("collagen")) {
		return {
			currentInventory: {
				availableForSale: true,
				inventoryQuantity: 1000,
			},
			isLoading: false,
		};
	}

	const currentInventory =
		inventoryData?.productByHandle?.variants?.edges?.find(
			({ node }) => node.sku === sku,
		);

	return { currentInventory: currentInventory?.node, isLoading };
};

const UpsellProduct = ({
	node,
	selectProduct,
	productIndex,
	setUpsellsAvailable,
}) => {
	const [selectedVariant, setSelectedVariant] = useState(0);
	const [loading, setLoading] = useState(false);

	let { title, variants, featuredImage, handle, productType, tags } = node;

	variants = variants.filter((item) => item.availableForSale);

	let sizes = title.split(" - ").filter((item) => item !== "Special Offer");

	let size = "";
	if (productType === ProductTypes.TEARRIBLE) {
		size = sizes.pop();
	} else {
		size = sizes[0];
	}

	let variant = variants[selectedVariant];
	const { currentInventory, isLoading } = usePrimaryInventory(
		handle,
		variant?.sku,
	);

	useEffect(() => {
		if (isLoading === false) {
			const isAvailableForSale =
				currentInventory?.availableForSale &&
				currentInventory?.inventoryQuantity > 0;

			setUpsellsAvailable((prev) => {
				prev[productIndex] = isAvailableForSale;
				return [...prev];
			});
		}
	}, [isLoading]);

	if (!variant) {
		return null;
	}

	const isAvailableForSale =
		currentInventory?.availableForSale &&
		currentInventory?.inventoryQuantity > 5;

	const currencyCode = variant?.presentmentPrices?.[0]?.price?.currencyCode;

	const image =
		variant?.image?.localFile?.childImageSharp?.fluid ||
		featuredImage?.localFile.childImageSharp.fluid;
	if (!variant) return null;
	const { price, availableForSale, compareAtPrice } = variant;

	const ctaStyles = {
		textAlign: "center",
		marginTop: "0",
		padding: "1rem 1rem",
	};
	if (!availableForSale) return null;

	const selectedOptions = variant?.selectedOptions?.reduce((acc, item) => {
		acc[item.name] = item.value;
		return acc;
	}, {});

	const selectOption = (name, value) => {
		const newOptions = { ...selectedOptions, [name]: value };

		let newIndex = variants.findIndex((item) => {
			const { selectedOptions: variantOptions } = item;
			const variantOptionsObject = variantOptions.reduce((acc, item) => {
				const { name: variantName, value: variantValue } = item;
				acc[variantName] = variantValue;
				return acc;
			}, {});

			return Object.entries(newOptions).reduce(
				(acc, [newOptionName, newOptionValue]) => {
					if (!acc) {
						return acc;
					}

					if (
						variantOptionsObject[newOptionName] !== newOptionValue
					) {
						return false;
					}

					return acc;
				},
				true,
			);
		});

		// Reset based on size if colors do not match.
		if (newIndex === -1 && name === "Size") {
			newIndex = variants.findIndex((item) => {
				const { selectedOptions: variantOptions } = item;
				const varaintOptionsObject = variantOptions.reduce(
					(acc, item) => {
						const { name: variantName, value: variantValue } = item;
						acc[variantName] = variantValue;
						return acc;
					},
					{},
				);

				return Object.entries(newOptions).reduce(
					(acc, [newOptionName, newOptionValue]) => {
						if (!acc || newOptionName !== "Size") {
							return acc;
						}

						if (
							varaintOptionsObject[newOptionName] !==
							newOptionValue
						) {
							return false;
						}

						return acc;
					},
					true,
				);
			});
		}

		if (newIndex !== -1) {
			setSelectedVariant(newIndex);
		} else {
			setSelectedVariant(0);
		}
	};

	const options = {
		leash: [
			{
				name: "Size",
				component: (
					<LeashSize
						selectedOptions={selectedOptions}
						setOption={selectOption}
					/>
				),
			},
			{
				name: "Color",
				component: (
					<LeashColor
						selectedOptions={selectedOptions}
						setOption={selectOption}
					/>
				),
			},
		],
		collar: [
			{
				name: "Size",
				component: (
					<CollarSize
						selectedOptions={selectedOptions}
						setOption={selectOption}
					/>
				),
			},
			{
				name: "Color",
				component: (
					<CollarColor
						selectedOptions={selectedOptions}
						setOption={selectOption}
					/>
				),
			},
		],
		harness: [
			{
				name: "Size",
				component: (
					<HarnessSize
						selectedOptions={selectedOptions}
						setOption={selectOption}
					/>
				),
			},
		],
		"poop bag holder": [
			{
				name: "Color",
				component: (
					<PoopBagHolderColor
						selectedOptions={selectedOptions}
						setOption={selectOption}
					/>
				),
			},
		],
	};

	let addToCartVerbiage = "ADD TO CART";

	if (tags.includes("Pre Order")) {
		addToCartVerbiage = "PRE ORDER";
	}

	return (
		<div className="upsell-select__member" key={variant.id}>
			<div className="upsell-select__image-wrapper">
				<Img
					fluid={image}
					alt={title}
					className="upsell-select__image"
				/>
			</div>
			<div className="upsell-select__title">
				{size
					.replace("- special offer", "")
					.replace("tearrible combo: ", "")}
			</div>
			<div className="upsell-select__text">
				<div>
					{/* {size.replace('- special offer', '').replace('tearrible combo: ', '')} */}
					<div className="upsell-select__description">
						{title.includes("Combo")
							? variant.title.replace("Default Title", "Combo")
							: variant.title.replace("Default Title", "")}
					</div>
				</div>
				<div className="upsell-select__right">
					<div>
						{currencyCode === "USD" && "$ "}
						{price}
						{currencyCode &&
							currencyCode !== "USD" &&
							` ${currencyCode}`}
					</div>
					{price !== compareAtPrice && (
						<div className="upsell-select__compare-price">
							{currencyCode === "USD" && "$ "}
							{compareAtPrice}
						</div>
					)}
				</div>
			</div>
			{node.size &&
				Array.isArray(options[node.size]) &&
				options[node.size].map((option) => {
					if (!option.name || !option.component) {
						return null;
					}

					return (
						<div
							className="upsell-select__size-options"
							key={option.name}
						>
							{option.component}
						</div>
					);
				})}
			<CTA
				disabled={isLoading || !isAvailableForSale}
				loading={loading}
				styles={ctaStyles}
				title={
					availableForSale && isAvailableForSale
						? addToCartVerbiage
						: isLoading
							? addToCartVerbiage
							: `SOLD OUT`
				}
				inverted={!availableForSale}
				onClick={() => {
					if (!availableForSale) return;
					setLoading(true);
					selectProduct(variant).catch(() => setLoading(false));
				}}
			/>
		</div>
	);
};

const UpsellSelect = ({
	data,
	cart,
	selectProduct,
	queryString,
	preOrderAuthorized,
}) => {
	// eslint-disable-next-line
	let hasNoSqueak = false;
	const domain = process.env.GATSBY_MYSHOPIFY_URL || "default";

	const currentCartPrice = parseFloat(cart?.subtotalPrice?.amount);
	const hasPreOrderItem = cart?.lineItems?.find((item) => {
		return item.tags.includes("Pre Order");
	});

	// Filter out free products that are automatically added to ensure
	// calculation of 'onlyOneItem' is correct.
	const filteredLineItems =
		Array.isArray(cart.lineItems) &&
		cart.lineItems
			.filter((item) => {
				return item.sku !== "JSPBR";
			})
			.map((node) => {
				const { title } = node;
				let size = title
					.split(" - ")
					.filter((item) => item !== "Special Offer")
					.pop()
					.replace(" Limited Edition", "")
					.replace(" ", ":")
					.toLowerCase();

				if (title.includes("Poop Bag Holder")) {
					size = "poop-bag-holder";
				}

				if (title.includes("Chew & Play Combo:")) {
					const comboSize = title
						.replace("Chew & Play Combo: ", "")
						.toLowerCase();
					size = `chew-play-combo:${comboSize}`;
				}

				if (title.includes("Sniff & Play Combo:")) {
					const comboSize = title
						.replace("Sniff & Play Combo: ", "")
						.toLowerCase();
					size = `sniff-play-combo:${comboSize}`;
				}

				if (title.includes("Tearrible Combo:")) {
					const comboSize = title
						.replace("Tearrible Combo: ", "")
						.replace(" + ", ":")
						.toLowerCase();
					size = comboSize;
				}

				if (title.includes("Collagen")) {
					const chewSize = title
						.replace("Baloomba Collagen Stix - 6 Inch ", "")
						.replace(" 5 Pack - Special Offer", "")
						.toLowerCase();

					size = `collagen:${chewSize}`;
				}

				node.size = size;
				return node;
			});

	const onlyOneItem =
		Array.isArray(filteredLineItems) &&
		filteredLineItems.length === 1 &&
		filteredLineItems[0].quantity === 1;

	hasNoSqueak =
		cart &&
		Array.isArray(cart.lineItems) &&
		cart.lineItems.reduce((acc, item) => {
			if (!item.variantTitle) {
				return acc;
			}

			if (item.variantTitle.includes("No Squeaker")) {
				return true;
			}

			return acc;
		}, hasNoSqueak);

	const hasVIPProduct = cart?.lineItems?.find((item) => {
		return item.tags.includes("VIP");
	});

	let { products } = data.specialOffers.edges[0].node;
	let { products: combos } = data?.combos?.edges?.[0]?.node || {};

	combos = combos
		?.map((node) => {
			const { title } = node;
			const sizes = title
				.replace("Tearrible Combo: ", "")
				.toLowerCase()
				.split(" + ");

			node.size = sizes.join(":");
			return node;
		})
		.filter((item) => {
			return (
				!item.tags.includes("Hide") && !item.tags.includes("Sold Out")
			);
		});

	let gear = products
		?.filter((item) => item.tags.includes("Gear"))
		.map((item) => {
			const size = item.productType.toLowerCase();
			return { ...item, size };
		});

	const vipProducts = products
		.filter((item) => {
			return item.tags.includes("VIP");
		})
		.reduce((acc, next) => {
			next.variants.forEach((variant) => {
				const newItem = {
					...next,
					variants: [variant],
				};

				acc.push(newItem);
			});
			return acc;
		}, [])
		.filter((item) => {
			if (domain !== "default") return true;

			if (!item.title.includes("Christmas Tree")) {
				return true;
			}

			return false;
		});

	products = products
		.filter((item) => {
			return (
				!item.tags.includes("Hide") && !item.tags.includes("Sold Out")
			);
		})
		.reduce((acc, next) => {
			next.variants.forEach((variant) => {
				const newItem = {
					...next,
					variants: [variant],
				};

				acc.push(newItem);
			});
			return acc;
		}, [])
		.map((node) => {
			const { title } = node;
			let size = title
				.split(" - ")
				.filter((item) => item !== "Special Offer")
				.pop()
				.replace(" Limited Edition", "")
				.replace(" ", ":")
				.toLowerCase();

			if (title.includes("Poop Bag Holder")) {
				size = "poop-bag-holder";
			}

			if (title.includes("Yak")) {
				const yakSize = node.variants[0].title.toLowerCase();
				size = `yak:${yakSize}`;
			}

			if (title.includes("Collagen")) {
				const chewSize = title
					.replace("Baloomba Collagen Stix - 6 Inch ", "")
					.replace(" 5 Pack - Special Offer", "")
					.toLowerCase();

				size = `collagen:${chewSize}`;
			}

			if (title.includes("Duck")) {
				const variantSize = node.variants[0].title
					.replace(" oz", "")
					.toLowerCase();
				size = `duck:${variantSize}`;
			}

			if (title.includes("PBJ")) {
				size = `pbj`;
			}

			if (title.includes("Chew & Play")) {
				size = `chew-play:${size}`;
			}

			if (title.includes("Bully Braids")) {
				if (title.includes("6 Pack")) {
					size = `bully:6`;
				}
				// if (title.includes("12 Inch")) {
				// 	size = `bully:12`;
				// }
			}

			if (title.includes("Bully Sticks")) {
				if (title.includes("6 Pack")) {
					size = `stick:6`;
				}
				if (title.includes("12 Pack")) {
					size = `stick:12`;
				}
			}

			if (title.includes("Jumbo Beef")) {
				size = `beef:xl`;
			}

			if (title.includes("Chickie Nugs")) {
				size = `chickie`;
			}

			if (title.includes("Beef Cheek Chips - 5 Pack")) {
				size = `cheek:5`;
			}

			node.size = size;
			return node;
		})
		.filter((item) => !item.tags.includes("VIP"))
		.sort((a, _) => {
			if (a.productType === "Tearrible") return -1;
			return 1;
		})
		.sort((a, b) => {
			if (!tearribleStyles[a.size] || !tearribleStyles[b.size]) {
				return 0;
			}

			const aSize = tearribleStyles[a.size].index;
			const bSize = tearribleStyles[b.size].index;

			if (aSize < bSize) return -1;
			if (aSize > bSize) return 1;
			return 0;
		})
		.filter((item) => item.variants[0].title !== "No Squeaker")
		// USA Filters
		// Filter for Tiny
		.filter((item) => {
			if (domain !== "default") return true;

			if (item.size !== "tiny") {
				return true;
			}

			if (item?.variants?.[0]?.title === "Squeaker / Happy Yellow") {
				return true;
			}

			// Remove all tinies.
			return false;
		})
		// Filter for XL
		.filter((item) => {
			if (domain !== "default") return true;

			if (item.size !== "xl") {
				return true;
			}

			if (item?.variants?.[0]?.title === "Royal Blue") {
				return true;
			}

			return false;
		})
		// Filter for Medium
		.filter((item) => {
			if (domain !== "default") return true;

			if (item.size !== "medium") {
				return true;
			}

			if (item?.variants?.[0]?.title === "Cuddly Blue") {
				return true;
			}

			return false;
		})
		// Filter for Hammerhead
		.filter((item) => {
			if (domain !== "default") return true;

			if (item.size !== "hammerhead:shark") {
				return true;
			}

			// if (item?.variants?.[0]?.title === "Cyan") {
			// 	return true;
			// }

			return false;
		})
		// Filter for Octopus
		.filter((item) => {
			if (domain !== "default") return true;

			if (item.size !== "octopus") {
				return true;
			}

			if (item?.variants?.[0]?.title === "Pretty Pink") {
				return true;
			}

			return false;
		})
		// Filter for Stingray
		.filter((item) => {
			if (domain !== "default") return true;

			if (item.size !== "stingray") {
				return true;
			}

			if (item?.variants?.[0]?.title === "Pretty Pink") {
				return true;
			}

			return false;
		})
		// Filter for Poop Bag Holders
		.filter((item) => {
			if (domain !== "default") return true;

			if (item.size !== "poop-bag-holder") {
				return true;
			}

			return false;
		})
		// AU Filters
		// Hidden sizes
		.filter((item) => {
			if (domain !== "tearriblesau.myshopify.com") return true;

			const hiddenSizes = ["venus flytrap"];

			if (hiddenSizes.includes(item.size)) {
				return false;
			}

			return true;
		})
		// Filter for Tiny (AU)
		.filter((item) => {
			if (domain !== "tearriblesau.myshopify.com") return true;

			if (item.size !== "tiny") {
				return true;
			}

			if (item?.variants?.[0]?.title === "Happy Yellow") {
				return true;
			}

			return false;
		})
		// Filter for Medium (AU)
		.filter((item) => {
			if (domain !== "tearriblesau.myshopify.com") return true;

			if (item.size !== "medium") {
				return true;
			}

			if (item?.variants?.[0]?.title === "Happy Yellow") {
				return true;
			}

			return false;
		})
		// Filter for XL (AU)
		.filter((item) => {
			if (domain !== "tearriblesau.myshopify.com") return true;

			if (item.size !== "xl") {
				return true;
			}

			if (item?.variants?.[0]?.title === "Happy Yellow") {
				return true;
			}

			return false;
		})
		// Filter for Venus (AU)
		.filter((item) => {
			if (domain !== "tearriblesau.myshopify.com") return true;

			if (item.size !== "venus flytrap") {
				return true;
			}

			// if (item?.variants?.[0]?.title === "Large") {
			// 	return true;
			// }

			return false;
		})
		.map((item) => {
			if (item.title.includes("Yak")) {
				const yakSize = item.variants[0].title.toLowerCase();
				item.size = `yak:${yakSize}`;
			}

			return item;
		});

	if (!combos) combos = [];
	if (!gear) gear = [];
	if (!products) products = [];

	products = [...products, ...combos, ...gear];

	let upsellOptionsBySize = {
		alwaysShow: ["tiny", "medium", "xl"],
		default: ["tiny", "xl"],
		tiny: ["tiny", "xl"],
		medium: ["tiny", "xl"],
		xl: ["tiny", "xl"],
	};

	if (domain === "default" || domain === "tearribles.myshopify.com") {
		upsellOptionsBySize = {
			alwaysShow: [],
			default: ["chickie", "bully:6", "cheek:5"],
			tiny: ["duck:5", "collagen:thin", "stick:12"],
			medium: ["chickie", "bully:6", "cheek:5"],
			xl: ["pbj", "stick:6", "cheek:5"],
			virus: ["pbj", "stick:6", "cheek:5"],
			// "snowball:small": ["tiny", "collagen:thin", "duck:5", "medium"],
			// "snowball:medium": ["medium", "bully:6", "pbj", "xl"],
			// "snowball:xl": ["xl", "beef:xl", "chickie", "yak:xl"],
		};
	}

	// if (currentCartPrice < 40 || currentCartPrice > 50) {
	// 	if (cart.lineItems.length === 1 && cart.lineItems[0].size === "tiny") {
	// 		upsellOptionsBySize.alwaysShow.push("duck:5");
	// 	} else {
	// 		upsellOptionsBySize.alwaysShow.push("pbj");
	// 	}
	// }

	let cartItemSize;
	cartItemSize =
		cart &&
		Array.isArray(filteredLineItems) &&
		filteredLineItems.length === 1
			? filteredLineItems[0].size
			: "default";

	// Select specific size for snowball.
	if (cartItemSize === "snowball") {
		cartItemSize = `${cartItemSize}:${filteredLineItems[0].variantTitle
			.split(" / ")
			.pop()
			.toLowerCase()}`;
	}

	if (cartItemSize && cartItemSize.includes("leash")) {
		cartItemSize = "leash";
	}

	if (onlyOneItem) {
		products = products.filter((item) => {
			if (upsellOptionsBySize.alwaysShow.includes(item.size)) {
				return true;
			}

			if (
				!upsellOptionsBySize[cartItemSize] &&
				upsellOptionsBySize.default
			) {
				return upsellOptionsBySize.default.includes(item.size);
			}

			return (
				cartItemSize &&
				upsellOptionsBySize[cartItemSize] &&
				upsellOptionsBySize[cartItemSize].includes(item.size)
			);
		});
	} else {
		products = products.filter((item) => {
			if (upsellOptionsBySize.alwaysShow.includes(item.size)) {
				return true;
			}

			return (
				upsellOptionsBySize.default &&
				upsellOptionsBySize.default.includes(item.size)
			);
		});
	}

	let numberOfTearribles;
	if (filteredLineItems.length > 0) {
		numberOfTearribles = filteredLineItems.reduce((acc, next) => {
			acc = acc + next.quantity;
			return acc;
		}, 0);
	}

	const goToCheckout = () => {
		// TODO: Navigate to a loading screen. "Securing your items." or something.
		navigateToCheckout(cart, queryString);
	};

	const hasUpsell =
		cart &&
		cart.lineItems &&
		Array.isArray(cart.lineItems) &&
		cart.lineItems.find((item) => {
			return item.title.includes("Special Offer");
		});

	const [upsellsAvailable, setUpsellsAvailable] = useState(
		Array(products.length).fill(null),
	);

	const upsellsInventoryIsLoading = upsellsAvailable.includes(null);

	if (!upsellsInventoryIsLoading && !upsellsAvailable.includes(true)) {
		goToCheckout();
		return (
			<div className="upsell-select">
				<Container>
					<p>Securing your items.</p>
				</Container>
			</div>
		);
	}

	if (Array.isArray(products) && products.length === 0) {
		goToCheckout();
		return (
			<div className="upsell-select">
				<Container>
					<p>Securing your items.</p>
				</Container>
			</div>
		);
	}

	return (
		<div className="upsell-select">
			<Container>
				<h3 className="upsell-select__header">Last Chance</h3>
				{!hasUpsell &&
					(numberOfTearribles === 1 ? (
						<p>
							Since you purchased a Tearrible, we're going to
							offer you a chance to purchase one more item for up
							to{" "}
							<span className="upsell-select__highlight">
								15% OFF!
							</span>
						</p>
					) : (
						<p>
							Since you purchased {numberOfTearribles} items,
							we're going to offer you a chance to purchase one
							more item for up to{" "}
							<span className="upsell-select__highlight">
								15% OFF!
							</span>
						</p>
					))}

				{/* {currentCartPrice < FREE_SHIPPING_PRICE &&
					currentCartPrice >= FREE_SHIPPING_FLOOR_PRICE &&
					!hasUpsell && (
						<p>
							Add one of these items to get{" "}
							<span className="upsell-select__highlight">
								FREE SHIPPING.
							</span>
						</p>
					)} */}

				{/* <p className="upsell-select__offer" onClick={() => navigate('/product/poop-bag-holder')}>
                    FREE Poop Bag Holder on all orders over $49.<br />($10 value) 💩
                </p> */}
				{/* <div
                    className="upsell-select__checkout"
                    onClick={goToCheckout}>
                        No Thanks, proceed to Checkout.
                </div> */}
				<div className="upsell-select__wrapper">
					{hasUpsell ? (
						<p>Securing your items.</p>
					) : (
						products.map((node, index) => {
							return (
								<UpsellProduct
									key={
										node.variant && node.variant.id
											? node.variant.id
											: index
									}
									node={node}
									selectProduct={selectProduct}
									setUpsellsAvailable={setUpsellsAvailable}
									productIndex={index}
								/>
							);
						})
					)}
					{!hasUpsell &&
						preOrderAuthorized &&
						vipProducts.map((node, index) => {
							return (
								<UpsellProduct
									key={
										node.variants?.[0] &&
										node.variants[0].id
											? node.variants[0].id
											: index
									}
									node={node}
									selectProduct={selectProduct}
									setUpsellsAvailable={setUpsellsAvailable}
									productIndex={index}
								/>
							);
						})}
				</div>
				<CTA
					title="No Thanks, proceed to Checkout"
					secondary
					onClick={goToCheckout}
					styles={{
						marginTop: "2rem",
						paddingLeft: "0",
						paddingRight: "0",
					}}
					suffixIcon="arrow"
				/>
			</Container>
		</div>
	);
};

export default UpsellSelect;
